export const homePageSEO = {
    title: 'Web Player: Streaming Konten Audio Original | Noice',
    meta: [
        {
            name: 'description',
            content:
                'Nikmati konten audio online gratis mulai dari podcast, audiobook, dan radio online di Noice.id.',
        },
        {
            property: 'og:title',
            content: 'Web Player: Streaming Konten Audio Original | Noice',
        },
        {
            property: 'og:description',
            content:
                'Nikmati konten audio online gratis mulai dari podcast, audiobook, dan radio online di Noice.id.',
        },
        {
            property: 'og:image',
            content: 'https://open.noice.id/img/noice-logo.svg',
        },
    ],
}

export const podcastPageSEO = {
    title: 'Web Player: Streaming Podcast Original Indonesia | Noice',
    meta: [
        {
            name: 'description',
            content:
                'Dengarkan ribuan ragam podcast original dari berbagai content creator eksklusif di Noice.id.',
        },
        {
            property: 'og:title',
            content: 'Web Player: Streaming Podcast Original Indonesia | Noice',
        },
        {
            property: 'og:description',
            content:
                'Dengarkan ribuan ragam podcast original dari berbagai content creator eksklusif di Noice.id.',
        },
        {
            property: 'og:image',
            content: 'https://open.noice.id/img/noice-logo.svg',
        },
    ],
}

export const audioseriesPageSEO = {
    title: 'Streaming Kumpulan Cerita & Novel Audioseries | Noice Web Player',
    meta: [
        {
            name: 'description',
            content:
                'Dengarkan ribuan ragam podcast original dari berbagai content creator eksklusif di Noice.id.',
        },
        {
            property: 'og:title',
            content:
                'Streaming Kumpulan Cerita & Novel Audioseries | Noice Web Player',
        },
        {
            property: 'og:description',
            content:
                'Dengarkan ribuan ragam podcast original dari berbagai content creator eksklusif di Noice.id.',
        },
        {
            property: 'og:image',
            content: 'https://open.noice.id/img/noice-logo.svg',
        },
    ],
}

export const radioPageSEO = {
    title: 'Live Streaming Radio FM Online Indonesia | Noice Web Player',
    meta: [
        {
            name: 'description',
            content:
                'Dengarkan livestreaming radio online teratas dan terlengkap di Indonesia dengan gratis dan mudah di Noice.id.',
        },
        {
            property: 'og:title',
            content: 'Web Player: Live Streaming Radio Online | Noice',
        },
        {
            property: 'og:description',
            content:
                'Dengarkan livestreaming radio online teratas dan terlengkap di Indonesia dengan gratis dan mudah di Noice.id.',
        },
        {
            property: 'og:image',
            content: 'https://open.noice.id/img/noice-logo.svg',
        },
    ],
}

export const audiobookPageSEO = {
    title: 'Web Player: Streaming Audiobook Online | Noice',
    meta: [
        {
            name: 'description',
            content:
                'Dengarkan audiobook berbahasa Indonesia secara gratis dan praktis di Noice. Temukan ragam genre mulai dari horor, religi, lifestyle, dan  masih banyak lagi.',
        },
        {
            property: 'og:title',
            content: 'Web Player: Streaming Audiobook Online | Noice',
        },
        {
            property: 'og:description',
            content:
                'Dengarkan audiobook berbahasa Indonesia secara gratis dan praktis di Noice. Temukan ragam genre mulai dari horor, religi, lifestyle, dan  masih banyak lagi.',
        },
        {
            property: 'og:image',
            content: 'https://open.noice.id/img/noice-logo.svg',
        },
    ],
}

export const themePageSEO = {
    title: 'Web Player: Podcast & Siaran Eksklusif untuk Rayakan Ramadhan Bareng Noice | Noice',
    meta: [
        {
            name: 'description',
            content:
                'Nantikan berbagai episode eksklusif paling update dan nongkrong bareng bintang tamu paling favorit bersama Noice Live untuk merayakan keseruan khas bulan Ramadhan!',
        },
        {
            property: 'og:title',
            content:
                'Web Player: Podcast & Siaran Eksklusif untuk Rayakan Ramadhan Bareng Noice | Noice',
        },
        {
            property: 'og:description',
            content:
                'Nantikan berbagai episode eksklusif paling update dan nongkrong bareng bintang tamu paling favorit bersama Noice Live untuk merayakan keseruan khas bulan Ramadhan!',
        },
        {
            property: 'og:image',
            content: 'https://open.noice.id/img/noice-logo.svg',
        },
        {
            property: 'og:type',
            content: 'website',
        },
        {
            property: 'og:locale',
            content: 'indonesian',
        },
    ],
}
