import {
    segmentList,
    updateOffset,
    updateSegmentStatus,
} from '@/store/actions/home'
import * as homeSelectors from '@/store/selectors/home'
import { getSegmentList } from '@/utils/getSegmentList'
import { useSession } from 'next-auth/react'
import dynamic from 'next/dynamic'
import { useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useSelector, useDispatch } from 'react-redux'

// Lazy load components
// const SkeletonContainer = dynamic(() =>
//     import('@/components/Home/VerticalRadioContent/SkeletonContainer')
// )
const FullWidthContent = dynamic(() =>
    import('@/components/Home/FullWidthContent')
)
const HorizontalContents = dynamic(() =>
    import('@/components/Home/HorizontalContents')
)
const HorizontalLivestreamContent = dynamic(() =>
    import('@/components/Home/HorizontalLivestreamContent')
)
const VerticalContents = dynamic(() =>
    import('@/components/Home/VerticalContents')
)
const HorizontalRecentContents = dynamic(() =>
    import('@/components/Home/HorizontalRecentContents')
)
const ThemeContent = dynamic(() => import('@/components/Home/ThemeContent'))

const TopPickCatalogs = dynamic(() =>
    import('@/components/Home/TopPickCatalogs')
)

const ForYou = () => {
    const homeSegmentList = useSelector(homeSelectors._homeSegmentList)
    const totalSegmentCount = useSelector(homeSelectors._totalHomeSegmentCount)
    const offset = useSelector(homeSelectors._homeOffset)
    const dispatch = useDispatch()
    const { data: session } = useSession()
    const limit = 10

    useEffect(() => {
        if (!session) return

        if (offset > 1) fetchData()

        async function fetchData() {
            const request = {
                page: 'home',
                offset,
                limit,
            }

            dispatch(updateSegmentStatus('fetching'))

            const data = await getSegmentList({
                request,
                token: session?.token,
            })

            if (data) {
                data.page = 'home'
                dispatch(segmentList(data))
                dispatch(updateSegmentStatus('success'))
            }
        }
    }, [dispatch, offset, session])

    const fetchMore = () => {
        // setOffset((prevOffset) => prevOffset + 1)
        dispatch(updateOffset('home'))
    }

    return (
        <>
            <InfiniteScroll
                dataLength={homeSegmentList.length}
                next={fetchMore}
                hasMore={totalSegmentCount > homeSegmentList.length}
            >
                {homeSegmentList &&
                    homeSegmentList.map((segment) => {
                        const {
                            viewOptions,
                            content = [],
                            title,
                            id,
                            description,
                            image,
                            imageMeta,
                        } = segment

                        if (
                            session &&
                            session?.isLoggedin &&
                            viewOptions?.orientation === 'horizontal' &&
                            viewOptions?.viewType == 'recently_played' &&
                            content.length
                        ) {
                            return (
                                <HorizontalRecentContents
                                    key={id}
                                    sectionTitle={title}
                                    contents={content}
                                    segmentId={id}
                                    viewType={viewOptions.viewType}
                                />
                            )
                        }

                        if (
                            viewOptions?.orientation === 'horizontal' &&
                            viewOptions?.viewType === 'livestream' &&
                            content.length
                        ) {
                            return (
                                <HorizontalLivestreamContent
                                    key={id}
                                    sectionTitle={title}
                                    contents={content}
                                    segmentId={id}
                                    viewType={viewOptions.viewType}
                                />
                            )
                        }

                        if (
                            viewOptions?.orientation === 'horizontal' &&
                            [
                                'audiobook',
                                'default',
                                'top_ranking_podcast',
                                'personalised_recommendation',
                            ].includes(viewOptions?.viewType) &&
                            content.length
                        ) {
                            return (
                                <HorizontalContents
                                    key={id}
                                    sectionTitle={title}
                                    contents={content}
                                    segmentId={id}
                                    viewType={viewOptions.viewType}
                                    headerViewType={viewOptions?.headerViewType}
                                    description={description}
                                    image={image}
                                    imageMeta={imageMeta}
                                />
                            )
                        }

                        if (
                            viewOptions?.orientation === 'horizontal' &&
                            viewOptions?.viewType == 'content_highlight' &&
                            content.length
                        ) {
                            return (
                                <FullWidthContent
                                    key={content[0].id}
                                    id={content[0].id}
                                    image={content[0].image}
                                    imageMeta={content[0].imageMeta}
                                    title={content[0].title}
                                    subTitle={content[0].subTitle}
                                    category={title}
                                    entityType={content[0].entityType}
                                    content={content[0]}
                                />
                            )
                        }

                        if (
                            viewOptions?.orientation === 'horizontal' &&
                            viewOptions?.viewType === 'content_horizontal' &&
                            content.length
                        ) {
                            return (
                                <VerticalContents
                                    key={id}
                                    sectionTitle={title}
                                    contents={content}
                                    segmentId={id}
                                    viewType={viewOptions.viewType}
                                />
                            )
                        }

                        if (
                            viewOptions?.orientation === 'vertical' &&
                            viewOptions?.viewType === 'collection' &&
                            content.length
                        ) {
                            return (
                                <ThemeContent
                                    key={id}
                                    sectionTitle={title}
                                    contents={content}
                                    segmentId={id}
                                    viewType={viewOptions.viewType}
                                />
                            )
                        }

                        if (
                            viewOptions?.orientation === 'vertical' &&
                            viewOptions?.viewType === 'quick_picks' &&
                            content.length
                        ) {
                            return (
                                <TopPickCatalogs
                                    key={id}
                                    sectionTitle={title}
                                    contents={content}
                                    id={id}
                                    viewType={viewOptions.viewType}
                                />
                            )
                        }
                    })}
            </InfiniteScroll>
        </>
    )
}

export default ForYou
