import { getFetcher } from './auth'

export const getSegmentList = async ({ request, token }) => {
    const queryString = new URLSearchParams(request).toString()

    try {
        const data = await getFetcher(
            `segment-api/segment?${queryString}`,
            token
        )

        return data
    } catch (error) {
        console.log('segment list error', error)
        return error
    }
}
