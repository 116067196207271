import { signIn } from 'next-auth/react'
import { useEffect } from 'react'

export const useGuestLoginFlow = (guestTokenResponse) => {
    useEffect(() => {
        const checkGuestSession = async () => {
            const res = await fetch('/api/auth/session')
            const session = await res.json()

            if (!session?.token && guestTokenResponse) {
                signIn('guest', { redirect: false, guestTokenResponse })
            }
        }

        const id = setTimeout(() => {
            checkGuestSession()
        }, 1000)

        return () => {
            clearTimeout(id)
        }
    }, [guestTokenResponse])
}
