import PageLayout from '@/components/Layouts/PageLayout'
import ForYou from '@/components/Pages/ForYou'
import {
    AmplitudeEventTracking,
    EventTracking,
    getGuestRelatedProps,
} from '@/helpers'
import { useGuestLoginFlow } from '@/hooks'
import { wrapper } from '@/store'
import {
    segmentList,
    updateNav,
    updateSegmentStatus,
    updateTab,
} from '@/store/actions/home'
import {
    initializeUserProfilePage,
    fetchUserProfileStatus,
    setProfileDetails,
} from '@/store/actions/user'
import { NAVTYPES, TABTYPES } from '@/store/types/home'
import { getUserProfileData } from '@/utils/api-ssr'
import { logOut } from '@/utils/auth'
import { getBanners } from '@/utils/getBanners'
import { getSegmentList } from '@/utils/getSegmentList'
import { getUpdatedToken } from '@/utils/getUpdatedToken'
import { homePageSEO } from '@/utils/seo'
import { signIn, useSession } from 'next-auth/react'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

const HomeCarousel = dynamic(() => import('@/components/Home/HomeCarousel'))

const HomePage = ({
    banner,
    homeSegmentList,
    tokenExpiredFromSession,
    guestTokenResponse,
}) => {
    useGuestLoginFlow(guestTokenResponse)

    const dispatch = useDispatch()
    const router = useRouter()
    const { data: session, status } = useSession()

    useEffect(() => {
        if (tokenExpiredFromSession) logOut()
    }, [tokenExpiredFromSession])

    useEffect(() => {
        dispatch(updateTab(TABTYPES.FOR_YOU))
        dispatch(updateNav(NAVTYPES.HOME))

        // Event Tracking

        if (session?.token) {
            AmplitudeEventTracking(
                'home page viewed',
                {
                    'tab name': 'Buat Kamu',
                },
                session
            )
            EventTracking(
                'for_you_opened',
                {
                    trackOnPostHog: true,
                },
                session
            )
        }
    }, [session, dispatch, homeSegmentList])

    const userInfo = session?.userInfo
    const userName = userInfo?.userName

    useEffect(() => {
        const getUserDetails = async () => {
            const request = {
                userId: session?.userInfo?.id,
                page: 1,
                limit: 10,
                includeEntities: `["userActions", "user", "live"]`,
                aggregations: 'listeningTime|followers|followees',
                profileUserId: session?.userInfo?.id,
            }

            const profileData = await getUserProfileData(
                request,
                session?.token
            )

            dispatch(initializeUserProfilePage(profileData))
            dispatch(setProfileDetails(profileData))
            dispatch(fetchUserProfileStatus('success'))
        }

        if (status === 'authenticated' && userName) {
            getUserDetails(session?.token)
        }
    }, [status, session, dispatch, userName])

    useEffect(() => {
        if (router.isReady) {
            const parsedHash = new URLSearchParams(window.location.hash)
            const id_token = parsedHash.get('id_token')

            if (id_token && session?.token) {
                signIn('apple', {
                    accessToken: id_token,
                    token: session?.token,
                    callbackUrl: '/',
                })
            }
        }
    }, [router, status, session?.token])

    return (
        <PageLayout>
            <Head>
                <title>{homePageSEO.title}</title>
                {homePageSEO.meta.map((meta, index) => {
                    const metaKeys = Object.keys(meta)

                    return React.createElement('meta', {
                        key: index,
                        [metaKeys[0]]: meta[metaKeys[0]],
                        [metaKeys[1]]: meta[metaKeys[1]],
                    })
                })}
                {/** <link rel="canonical" href="https://open.noice.id" /> */}
            </Head>

            {banner && !!banner?.data?.length && (
                <HomeCarousel banner={banner?.data} />
            )}

            <ForYou />
        </PageLayout>
    )
}

export const getServerSideProps = wrapper.getServerSideProps(
    (store) =>
        async ({ req, res }) => {
            const { token, session, guestTokenResponse } =
                await getUpdatedToken(req, res)

            // if (!session || session?.isGuest) {
            //     res.setHeader(
            //         'Cache-Control',
            //         'public, s-maxage=20, stale-while-revalidate=59'
            //     )
            // } else if (session?.userName || !session?.isGuest) {
            //     res.setHeader('Cache-Control', 'no-store')
            // }

            const homeSegmentRequest = {
                page: 'home',
                offset: 1,
                limit: 10,
            }

            const banner = await getBanners('home', token)

            const homeSegmentList = await getSegmentList({
                request: homeSegmentRequest,
                token,
            })

            if (
                homeSegmentList?.message?.toLowerCase().includes('token') ||
                banner?.message?.toLowerCase().includes('token')
            ) {
                return {
                    props: {
                        tokenExpiredFromSession: true,
                    },
                }
            }

            homeSegmentList.page = 'home'

            await store.dispatch(segmentList(homeSegmentList))
            store.dispatch(updateSegmentStatus('success'))

            return {
                props: {
                    banner: banner || {},
                    homeSegmentList: homeSegmentList || {},
                    ...getGuestRelatedProps(session, guestTokenResponse),
                },
            }
        }
)

export default HomePage
